import type {
  TProduct,
  AlgoliaProductType,
  ProductTileType,
  ProductTileAssets,
  ProductCardHorizontal,
  VisenzeProductTypeWithClPrices,
} from './../components/WrapperProductTile.props'
import { convertStringToSlug } from '@design-system/utils/stringUtils'
import type { Brand } from '@design-system/index'

export const useWrapperProductTile = () => {
  const { currentWebsite, isGiorgioArmaniWebsite } = useWebsite()
  const { pdpPath, homeBrandPath } = useRouteHelper()

  /**
   * @title NormalizeImages
   * @description Normalizes product images.
   */
  const normalizeImages = (
    assets:
      | Array<{
          URL?: string
          ImageURL?: string
          priority?: number
          type?: string
        }>
      | undefined
  ): ProductTileAssets[] => {
    if (!assets || !Array.isArray(assets) || assets.length === 0) {
      return []
    }
    // order assets by priority
    const assetsBypriority = assets.sort(
      ({ priority: a }, { priority: b }) => a! - b!
    )

    return assetsBypriority.map(media => ({
      URL: media.URL ?? media.ImageURL ?? '',
      priority: media.priority,
      type: media.type === 'mov' ? 'TResponsiveVideo' : 'TResponsiveImage',
    }))
  }

  /**
   * @title normalizeProduct
   * @description Normalizes a generic TProduct object.
   *
   * @param {TProduct} product - The product to normalize.
   * @returns {ProductTileType | undefined} The normalized product.
   */
  const normalizeProduct = (product: TProduct): ProductTileType | undefined => {
    if (typeof (product as AlgoliaProductType).objectID !== 'undefined') {
      const item = product as AlgoliaProductType & { product_url?: string }
      const totalAvailableQty = item.size.reduce(
        (acc, { avEcom, avRet }) => acc + ((avEcom ?? 0) + (avRet ?? 0)),
        0
      )

      return {
        sku: item.objectID,
        name: item.Name,
        brand: item.Brand,
        linkToProduct: item.product_url
          ? new URL(item.product_url).pathname.replaceAll(/,/g, '')
          : pdpPath(item, true, false),
        categories: extractCategories(item.hierarchicalCategories),
        isAvailable3d: !!item.assets?.find(media => media.type === 'glb'),
        lastInStock: totalAvailableQty === 1,
        images: normalizeImages(item.images || item.assets),
        labels: item.tags,
        colors: item['alternative-color']
          ? item['alternative-color'].length + 1
          : 1,
        inventory: {
          isAvailableOnline: item?.size.length
            ? item?.size?.some(
                // @ts-ignore
                item => item.avEcom && item.avEcom > 0
              )
            : false,
          isAvailableInStore: item?.size.length
            ? item?.size?.some(
                // @ts-ignore
                item => item.avRet && item.avRet > 0
              )
            : false,
        },
        defaultColor: item.ColorDesc ?? '',
        // if the price has already been provided by CL use it, otherwise create the fallback price using the algolia FullPrice
        price: item.clPrices ?? {
          originalPrice: item.FullPrice,
        },
        defaultSku: item?.size?.[0]?.SKU ?? '',
        MFC: item.MFC,
        infoCommerce: item.infoCommerce ?? false,
        showLabels: !isGiorgioArmaniWebsite.value,
      }
    } else if (
      typeof (product as VisenzeProductTypeWithClPrices).product_id !==
      'undefined'
    ) {
      const item = product as VisenzeProductTypeWithClPrices

      const {
        title,
        brand,
        tags,
        alternative_color,
        product_url,
        size_list,
        color,
        price,
        additional_image_url,
      } = item?.data ?? {}

      const sizes = (size_list ?? []).map(item => stringToObject(item))
      const images = [item.main_image_url, ...(additional_image_url ?? [])]

      const assets = images.map((media, index) => {
        return {
          URL: media,
          priority: index + 1,
          type: getFileTypeFromUrl(media),
        }
      })

      let productUrl = product_url
      if (!productUrl) {
        console.error(
          `Visenze (prd. ${item.product_id}): the 'product_url' is not valid`
        )
        productUrl = homeBrandPath.value
      }

      return {
        sku: item.product_id,
        name: title ?? '',
        brand: brand ?? '',
        linkToProduct: productUrl,
        // Temporarly comment out (we're waiting the `nameURL` field on Visenze)
        // linkToProduct: pdpPath(
        //   {
        //     NameURL: [
        //       {
        //         lang: 'en',
        //         name: title,
        //       },
        //     ],
        //     objectID: item.product_id,
        //     Brand: brand,
        //   },
        //   true,
        //   false
        // ),
        isAvailable3d: !!assets.find(media => media.type === 'glb'),
        labels: tags ?? [],
        colors: alternative_color ? alternative_color.length + 1 : 1,
        inventory: {
          isAvailableOnline: sizes.some(
            // @ts-ignore
            item => item.avEcom && item.avEcom > 0
          ),
          isAvailableInStore: sizes.some(
            // @ts-ignore
            item => item.avRet && item.avRet > 0
          ),
        },
        images: normalizeImages(assets),
        price: item.clPrices ?? {
          originalPrice: Number(price.value),
        },
        defaultColor: color ?? '',
        defaultSku: sizes.at(0)?.SKU ?? '',
        showLabels: !isGiorgioArmaniWebsite.value,
      }
    }
  }

  /**
   * @title transformToProductCardHorizontal
   * @description Transforms a product into a horizontal product card
   *
   * @param {ProductTileType} product - The product to be transformed.
   * @param {ProductTilePrice} price - The price details of the product.
   * @returns {ProductCardHorizontalProps}
   */
  const transformToProductCardHorizontal = (
    product: ProductTileType
  ): ProductCardHorizontal => {
    return {
      id: product.sku,
      image: product.images[0]?.URL,
      info: {
        name: product.name,
        oldPrice: product.price.discountedPrice
          ? product.price.originalPrice
          : undefined,
        price: product.price.discountedPrice ?? product.price.originalPrice,
        percentageDiscount: product.price.discountLabel,
        color: product.defaultColor,
      },
      metadata: product.brand
        ? (convertStringToSlug(product.brand) as Brand)
        : currentWebsite.value,
      path: product.linkToProduct,
      skuCode: product.defaultSku ?? '',
      available:
        product.inventory.isAvailableInStore ||
        product.inventory.isAvailableOnline,
      quantity: 1,
      itemTotalPrice: product.price.originalPrice,
    }
  }

  return {
    normalizeProduct,
    transformToProductCardHorizontal,
  }
}
